@use '../../styles/constants.scss' as *;

.projects {
  @include contentPage;

  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  overflow: hidden;
  width: 100%;
  padding: 0;

  & > .projects-inner {
    height: 90%;
    width: 100%;
    overflow: hidden;
    position: relative;
    top: 10%;
    
    .scroll-hider {
      @include flex(column, flex-start, center);

      height: 100%;
      width: calc(100% + $margin-lg);
      overflow: scroll;
    }

    .info {
      height: max-content;
      width: 100%;
      
      .paragraph2 {
        box-sizing: border-box;
        line-height: 1.2;
        padding: 0 $margin-lg;
        width: calc(100% - $margin-lg);
      }
    }
  }

  .footer {
    @include flex(column);

    padding: 12.5% 0;
    margin-right: $margin-lg;
    width: calc(100% - $margin-lg);
    font-family: $typeface-secondary;
    background: linear-gradient(
      to right,
      #59ffff,
      #e9f951,
    );

    & > .paragraph2 {
      color: black;
    }

    .cta {
      background-color: black;
      border-radius: 45px;
      border: none;
      margin: $margin-lg 0 $margin-sm;
      cursor: pointer;
      color: white;

      .paragraph2 {
        font-family: $typeface-secondary;
        border-radius: 45px;
        height: 100%;
        font-size: 1rem;
        padding: $margin-xs $margin-lg;
      }
    }
  }

  @include media($break-lg) {
    .back {
      left: 2.5%;
      top: 7.5%;
    }
    
    .logo {
      right: 2.5%;
      top: 7.5%;
    }

    & > .projects-inner {
      top: 17.5%;
      height: 82.5%;

      .info {
        margin: 0 0 0 6.25%;
        padding: 0;
        width: 37.5%;

        .paragraph2 {
          padding: 0;
          width: 100%;
          font-size: 1.2rem;
        }
      }

      .scroll-hider {
        width: calc(100% + $margin-lg);
        height: calc(100% + $margin-lg);
        overflow: scroll;
      }

      .footer {
        box-sizing: border-box;
        margin: 0;
        padding: 5% 0;
        width: 100%;
      }
    }
  }
}
