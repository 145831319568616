@use '../../styles/constants.scss' as *;

.paragraph1 {
  font-size: 8vw;
  line-height: 1.4;
  margin: 0;

  @include media($break-lg) {
    font-size: 2rem;
  }
}
