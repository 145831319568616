@use '../../styles/constants.scss' as *;

.background {
  @include page;

  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: background 500ms ease-out;
  z-index: 0;

  &.dark, &.darkBgDarkPill {
    background: black;
  }

  &.light, &.lightBgLightPill {
    background: white;
  }

  .ball {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);

    &.b1 {
      width: 100vh;
    }

    &.b2 {
      width: 75vh;
    }

    &.b3 {
      width: 150vh;
      z-index: 5;
    }

    @include media($break-lg) {
      &.b1 {
        width: 95vw;
      }
  
      &.b2 {
        width: 70vw;
      }
  
      &.b3 {
        width: 100vw;
      }
  
      &.b4 {
        width: 75vw;
      }
    }
  }

}
