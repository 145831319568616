@use '../../styles/constants.scss' as *;

.heading {
  @include flex(column);

  font-family: $typeface-tertiary;
  font-weight: 600;
  font-size: 35vw;
  margin: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  letter-spacing: 0;
  line-height: 0.9;
  padding-bottom: $margin-lg;

  .marquee {
    color: transparent;
    position: relative;
    letter-spacing: 0;
    width: 100%;
    background-clip: text;
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 3px;

    .spacer {
      visibility: hidden;
    }

    .wrapped {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);

      &.w1 {
        transform: translateX(-20%);
      }
  
      &.w2 {
        left: 50%;
        transform: translateX(-53.25%);
      }
  
      &.w3 {
        transform: translateX(-94.5%);
      }
    }

  }
  
  .desktop {
    display: none;
    position: absolute;
    top: 0;

    background-clip: text;
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 1px;
  }

  @include media($break-md) {
    font-size: 35vw;
  }

  @include media($break-lg) {
    font-size: 9.75vw;
    position: absolute;
    top: 15%;

    & > .marquee {
      & > .wrapped.w3 {
        transform: translateX(-135%);
      }
    }

    .desktop {
      display: block;
      position: relative;
      top: initial;
    }

    .mobile {
      display: none
    }
  }
}
