@use '../../styles/constants.scss' as *;

.success {
  @include contentPage;

  color: white;

  .success-inner {
    @include flex(column, space-evenly, flex-start);

    height: 400px;

    .paragraph1 {
      font-size: 12vw;
    }

    .paragraph2 {
      font-size: 5vw;
    }

    a {
      color: white;
    }

    .socials {
      @include flex(center, flex-start);
      
      margin: 10% $margin-sm 0;
      height: 3vh;
      width: 100%;

      .social {
        height: 100%;
        margin-right: 4vmax;

        img {
          height: 100%;
        }
      }
    }
  }

  @include media($break-md) {
    .success-inner {
      .socials {
        height: 10%;
      }
    }
  }

  @include media($break-lg) {
    .success-inner {
      height: 40%;
      width: 40%;

      .paragraph1 {
        font-size: 3rem;
      }

      .paragraph2 {
        font-size: 1rem;
      }

      .socials {
        height: 1.75vmax;

        .social {
          margin-right: 3vmax;
        }
      }
    }
  }
}
