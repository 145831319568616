/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * transducer-extended:
 *   - http://typekit.com/eulas/00000000000000007735ecd9
 *   - http://typekit.com/eulas/00000000000000007735ecd7
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-12-15 16:43:14 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=dxx7gjq&ht=tk&f=47842.47845&a=108023343&app=typekit&e=css");

@font-face {
font-family:"transducer-extended";
src:url("https://use.typekit.net/af/f993f3/00000000000000007735ecd9/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/f993f3/00000000000000007735ecd9/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/f993f3/00000000000000007735ecd9/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}

@font-face {
font-family:"transducer-extended";
src:url("https://use.typekit.net/af/3a72c7/00000000000000007735ecd7/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/3a72c7/00000000000000007735ecd7/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/3a72c7/00000000000000007735ecd7/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

.tk-transducer-extended { font-family: "transducer-extended",sans-serif; }
