@use '../../styles/constants.scss' as *;

.back {
  @include flex;

  border-radius: 45px;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  font-size: 1rem;
  top: 5%;
  left: $margin-xs;
  padding: 4px $margin-lg;
  transform: translateY(-50%);
  color: white;
  cursor: pointer;

  svg {
    margin-right: $margin-xs;
  }
}

.logo {
  position: absolute;
  top: 5%;
  right: $margin-lg;
  transform: translateY(-50%);
}

@include media($break-lg) {
  .back {
    left: 2.5%;
    top: 7.5%;
  }
  
  .logo {
    right: 2.5%;
    top: 7.5%;
  }
}
