@use './font.css';

@forward "./includeMedia.scss";

@font-face {
  font-family: SyneNoFill;
  src: url('./synebold.ttf');
}

$typeface-primary: 'Syne', sans-serif;
$typeface-secondary: 'SyneNoFill', sans-serif;
$typeface-tertiary: 'transducer-extended', sans-serif;
$typeweight-primary: 700;

$margin-xs: 6px;
$margin-sm: 12px;
$margin-md: 18px;
$margin-lg: 24px;
$margin-xl: 30px;
$margin-xxl: 36px;

$color-blue: #0038FF;
$color-red: #C03B5B;

$break-xs: "<376px";
$break-sm: "<768px";
$break-md: ">=768px";
$break-lg: ">1024px";

@mixin flex($direction: row, $justify: center, $align: center, $wrap: nowrap) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  flex-wrap: $wrap;
}

@mixin page() {
  @include flex(column);

  background-color: transparent;
  height: var(--windowheight);
  width: var(--windowwidth);
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

@mixin contentPage() {
  @include page;

  padding: 0 $margin-lg;
  height: 100%;
  width: calc(100% - (2 * $margin-lg));
}

@mixin rainbow-border($border-width: 3px, $border-radius: 25px) {
  position: relative;

  &::before {
    content: '';
    background: conic-gradient(          
      #95ffa0,
      #95b9ff,
      #d795ff,
      #ffa861,
      #ffd361,
      #95ffa0
    );
    border-radius: $border-radius;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  & > video, & > img {
    border: $border-width solid transparent;
    border-radius: $border-radius;
    box-sizing: border-box;
    display: block;
    position: relative;
    width: 100%;
  }
}

@mixin fade-transition() {
  &.fade-enter, &.fade-appear {
    opacity: 0;
    pointer-events: none;
  }
  
  &.fade-enter-active, &.fade-appear-active {
    opacity: 1;
    transition: opacity 0.2s ease-out;
    pointer-events: none;
  }
  
  &.fade-exit {
    opacity: 1;
  }
  
  &.fade-exit-active {
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }
}

.blue {
  color: $color-blue;
}

.italic {
  font-style: italic;
}

.notouch {
  pointer-events: none;
}

span.tm {
  font-size: 0.5em;
  vertical-align: top;
}
