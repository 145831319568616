@use '../../styles/constants.scss' as *;

.paragraph2 {
  font-size: 5.5vw;
  line-height: 1.4;
  margin: 0;

  @include media($break-md) {
    font-size: 4vw;
  }

  @include media($break-lg) {
    font-size: 1.5rem;
  }
}
