@use '../../styles/constants.scss' as *;

.contact-form {
  @include flex(column);

  width: 100%;

  .form-item {
    @include flex(column);

    margin: $margin-xs 0 $margin-sm;
    width: 100%;

    &.additional-info {
      margin-bottom: $margin-lg;
    }
  }

  label {
    @include flex(column, center, flex-start);
    
    position: relative;
    width: 100%;

    .paragraph2 {
      font-size: 0.8rem;
      margin: $margin-xs 0;
    }

    .error {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
      font-size: 0.7rem;
      color: $color-red;
      padding-top: $margin-xs;
      display: none;

      &.active {
        display: block;
      }
    }
  }

  input, textarea {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: $typeface-secondary;
    font-size: 1rem;
    font-weight: 500;
    padding: 5% $margin-sm;
    width: 100%;
    color: white;
    outline: none;

    &:focus {
      border: 1px solid $color-blue;
    }

    &.red {
      border: 1px solid $color-red;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.3)
    }
  }

  textarea {
    color: white;
    resize: none;
    padding: $margin-sm;
  }

  .count {
    width: 100%;
    font-size: 0.7rem;
    text-align: right;
    font-weight: 500;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    padding-top: $margin-xs;

    &.red {
      color: $color-red;
    }
  }

  .cta {
    background-color: transparent;
    border: none;
    cursor: pointer;      
    width: 100%;
    margin: $margin-md 0;
    padding: 0;
    transition: opacity 0.5s ease-out;
    opacity: 0.2;
    pointer-events: none;
    
    .paragraph2 {
      font-family: $typeface-secondary;
      border-radius: 45px;
      font-size: 1rem;
      color: black;
      padding: $margin-sm $margin-lg;
      background: linear-gradient(
        to right,
        #59ffff,
        #e9f951,
      );
    }

    &.enabled {
      opacity: 1;
      pointer-events: all;
    }
  }

  @include media($break-xs) {
    .form-item {
      label {
        .paragraph2 {
          margin: $margin-sm 0;
        }
      }
    }
  }

  @include media($break-lg) {
    input {
      padding: $margin-md $margin-sm;
    }

    .form-item {
      label {
        .paragraph2 {
          margin: $margin-sm 0;
        }
      }
    }

    .cta {
      & > p.paragraph2 {
        margin: 0;
      }
    }
  }
}
