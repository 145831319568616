@use '../../styles/constants.scss' as *;

.project-list {
  @include flex(column, flex-start, flex-start);

  width: calc(100% - (2 * $margin-lg));
  margin: 17.5% 0 12.5%;

  .project-item {
    width: calc(100% - $margin-lg);
    padding: $margin-md 0;

    .number {
      font-size: 4rem;
      position: absolute;
      right: -$margin-lg;
      top: -10px;
      font-family: $typeface-tertiary;
      font-weight: 600;
      line-height: 0;
      color: transparent;
      background-clip: text;
      -webkit-text-stroke-color: rgba(255, 255, 255, 0.3);
      -webkit-text-stroke-width: 1px;
    }

    .summary {
      position: relative;

      .paragraph1 {
        font-size: 1.5rem;
        line-height: 1.2;
      }
    }

    .cta {
      background-color: transparent;
      border: none;
      margin: $margin-sm 0;
      cursor: pointer;
      padding-left: 0;
      
      .paragraph2 {
        font-family: $typeface-secondary;
        border-radius: 45px;
        height: 100%;
        font-size: 1rem;
        color: black;
        padding: $margin-xs $margin-lg;
        background: linear-gradient(
          to right,
          #59ffff,
          #e9f951,
        );
      }
    }
  }

  @include media($break-lg) {
    align-items: center;
    margin: 7.5% 0;
    width: 100%;

    .project-item {
      width: 37.5%;
      margin-left: 6.25%;

      .number {
        right: 0;
      }
    }
  }
}
