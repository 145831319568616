@use '../../styles/constants.scss' as *;

.swipe-pill {
  @include flex;

  position: absolute;
  bottom: 7.5%;
  border-radius: 45px;
  font-size: 3.5vw;
  left: 50%;
  padding: 4px $margin-lg;
  overflow: hidden;
  transform: translate(-50%, 100%);
  transition: all 0.5s ease-out;
  display: none;

  &.active {
    display: flex;
  }
  
  & path {
    transition: all 0.5s ease-out;
  }

  &.dark, &.lightBgLightPill {
    background: rgba(0, 0, 0, 0.1);
    color: white;

    & path {
      stroke: white;
    }
  }

  &.light, &.darkBgDarkPill  {
    background: rgba(0, 0, 0, 0.1);
    color: black;

    & path {
      stroke: black;
    }
  }

  & > .blur {
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(4px);
    background-color: transparent;
    height: 100%;
    width: 100%;
  }

  & > .pill-inner {
    @include flex;

    height: min-content;
    width: fit-content;
    z-index: 1;

    .char {
      position: relative;
    }

    .space {
      margin-right: $margin-xs;
    }
  }

  .desktop {
    display: none;
  }

  @include media($break-md) {
    font-size: 1em;
    bottom: 0;
    transform: translate(-50%, -100%);
  }

  @include media($break-lg) {
    font-size: 1em;
    bottom: 0;
    transform: translate(-50%, -100%);

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }
  }
}
