@use './styles/constants.scss' as *;

#root, body, html {
  height: 100%;
  width: 100%;
  margin: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  font-family: $typeface-primary;
  font-weight: $typeweight-primary;
}
