@use '../../styles/constants.scss' as *;

.content {
  @include page;

  overflow: hidden;
  transition: all 0.5s ease-out;

  &.dark, &.darkBgDarkPill {
    background-color: black;
    color: white;
  }

  &.light, &.lightBgLightPill {
    background-color: white;
    color: black;
  }

  .who-we-are-1 {
    @include contentPage;
    @include flex(column, center, flex-start);

    background-color: transparent;

    .desktop {
      display: none;
    }

    @include media($break-lg) {
      align-items: center;

      .mobile {
        display: none;
      }

      .desktop {
        display: block;
      }
    }
  }

  .who-we-are-2 {
    @include contentPage;
    @include flex(column, flex-start, flex-start);
    
    .text {
      @include flex(column, flex-end);

      height: 85%;
      width: 85%;
      color: white;
    }

    @include media($break-lg) {
      justify-content: center;
      align-items: center;

      .text {
        justify-content: center;
        width: 40%;
      }
    }
  }

  .who-we-are-3 {
    @include flex(column, space-between, flex-start);

    height: 100%;
    padding: 0 $margin-lg;
    position: relative;
    width: calc(100% - (2 * $margin-lg));
    overflow: hidden;

    .media {
      position: relative;
      flex: 1;
      width: 100%;

      & > img {
        position: absolute;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

        &:nth-of-type(1) {
          height: 30%;
          top: 0;
          right: 0;

          @include media($break-xs) {
            height: 32.5%;
          }
        }

        &:nth-of-type(2) {
          height: 35%;
          top: 20%;
          left: -10%;

          @include media($break-xs) {
            height: 35%;
          }
        }

        &:nth-of-type(3) {
          height: 30%;
          top: 35%;
          right: -10%;
          z-index: 1;

          @include media($break-xs) {
            height: 35%;
            top: 37.5%;
          }
        }

        &:nth-of-type(4) {
          height: 40%;
          top: 50%;
          left: 0;

          @include media($break-xs) {
            height: 40%;
            top: 52.5%;
          }
        }
      }
    }
    
    .text {
      width: 90%;
      margin-bottom: 30%;
      position: relative;

      @include media($break-xs) {
        width: 95%;
      }

      & > p {
        font-size: 6.5vw;
        line-height: 1.2;
      }
    }

    @include media($break-md) {
      .text {    
        width: 100%;    
        margin-bottom: 15%;

        & > p {
          font-size: 4vw;
        }
      }
    }

    @include media($break-lg) {
      flex-direction: row;
      height: 100%;

      .media, .text {
        height: 100%;
      }

      .media {
        margin: 3%;
        box-sizing: border-box;
      }

      .text {
        @include flex;

        width: 50%;

        & > p {
          width: 480px;
          font-size: 2rem;
        }
      }
    }
  }

  .who-we-are-4 {
    @include contentPage;

    height: 100%;
    padding: 0 $margin-lg;
    position: absolute;
    text-align: center;
    width: calc(100% - (2 * $margin-lg));

    & > .gif-background, & > .shade {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
    }

    & > .gif-background {
      background: center/cover no-repeat url('./WhoWeAre/assets/gif1.gif');
    }

    & > .shade {
      background: rgba(0, 0, 0, 0.4);
    }

    & > .text {
      position: relative;
      color: white;
    }

    @include media($break-lg) {
      & > .text {
        width: 480px;
      }
    }
  }

  .fashion-1, .gaming, .entertainment-1, .social, .experiences-1, .wine-and-spirits {
    @include contentPage;
    @include flex(column-reverse, space-between);

    padding: 0;
    height: 100%;
    width: 100%;

    & > .text {
      @include flex(column);
      
      background-color: white;
      color: black;
      flex: 1;
      padding: 0 $margin-lg;
      position: relative;

      & > p {
        font-size: 6.5vw;
        line-height: 1.2;
      }
    }

    & > .media {
      @include flex;

      position: relative;

      .v1, .v2 {
        width: 50%;
      }

      .desktop {
        display: none;
      }
    }
  }

  .fashion-1 {
    @include media($break-lg) {
      flex-direction: row-reverse;

      .media {
        height: 100%;
        background-color: black;
        width: calc(100% / 3);
        position: relative;

        video.v1 {
          display: none;
        }

        & > .v2 {
          width: auto;
          height: 100%;
          object-fit: cover;
        }

        & > .v1 {
          @include rainbow-border;

          display: block;
          position: absolute;
          left: 0;
          top: 47.5%;
          z-index: 1;
          transform: translate(-66.7%, -50%);
          width: 65%;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
      }

      & > .text {
        height: 100%;

        & > p {
          font-size: 2rem;
          width: 50%;
          margin-right: 25%;
        }
      }
    }
  }

  .fashion-2, .experiences-2 {
    @include contentPage;
    @include flex(column);
    
    & > .media {
      position: relative;
      width: 100%;
      margin-bottom: 15%;
      
      & > .video-border {
        @include rainbow-border;
      }

      & > .text {
        @include flex;
  
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        height: 100%;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 25px;
        border: 3px solid transparent;
        background-clip: padding-box;
        box-sizing: border-box;
  
        & > p {
          position: relative;
          text-align: center;
        }
      }
    }

    @include media($break-md) {
      & > .media {
        width: auto;
        height: 85%;
        margin-bottom: 10%;

        & > .video-border {
          height: 100%;

          video {
            height: 100%;
          }
        }
      }
    }

    @include media($break-lg) {
      .media {
        @include flex(row-reverse, space-evenly);

        width: 100%;
        height: 100%;
        margin: 0;

        .text {
          position: relative;
          background-color: transparent;
          color: black;
          top: initial;
          left: initial;
          transform: none;
          width: min-content;
          justify-content: flex-start;
          
          .paragraph1 {
            text-align: left;
            width: 450px;

            .desktop {
              color: $color-blue;
            }
          }
        }

        .video-border {
          height: 85%;
          margin-bottom: 2.5%;
          filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));

          & > video {
            width: auto;
          }
        }
      }
    }
  }

  .impact {
    @include contentPage;

    margin: 0;
    position: relative;

    .impact-inner {
      @include flex(column);

      width: 100%;
      height: 100%;
      position: relative;
      padding-bottom: 15%;
    }

    .media, .text {
      width: 100%;
      height: 100%;
    }
    
    .media {
      position: absolute;
      top: 47.5%;
      width: 100%;
      height: 66.67%;
      transform: translateY(-50%);

      & > .video-border {
        @include rainbow-border(2px);

        position: absolute;
        width: 47.5%;

        &.vb1 {
          bottom: 0;
          left: 0;
        }

        &.vb2 {
          right: 0;
          top: 0;
        }
      }
    }

    .text {
      height: 70%;
      position: relative;
      width: 100%;

      .caption {
        background-color: black;
        width: min-content;
        padding: 0 $margin-sm;
      }

      .c2 {
        bottom: -5%;
        position: absolute;
        right: 0;

        p {
          white-space: nowrap;
          width: fit-content;
        }
      }

      .paragraph1 {
        font-weight: 500;
        font-size: 16vw;
        line-height: 0.9;
      }

      .paragraph2 {
        font-size: 7.5vw;
        font-weight: 500;
        line-height: 1.2;
      }

      .mobile {
        display: block;
      }
      
      .desktop {
        display: none;
      }

      img {
        width: 30vw;
        margin: $margin-sm 0;
      }
    }

    @include media($break-md) {
      .text {
        height: calc(100% - (2 * $margin-lg));
      }
    }

    @include media($break-lg) {
      .media {
        @include flex(row-reverse);

        height: 100%;

        & > .video-border {
          margin: $margin-lg;
          position: relative;
          width: 20%;

          &.vb1 {
            margin-top: 10%;
          }
          
          &.vb2 {
            margin-bottom: 10%;
          }
        }
      }

      .text {
        position: absolute;
        top: 47.5%;
        transform: translateY(-50%);
        width: 85%;
        height: 75%;

        .caption {
          width: fit-content;
          background-color: transparent;

          &.c2 {
            bottom: 0;
          }
        }

        .mobile {
          display: none;
        }

        .desktop {
          display: block;
        }

        .paragraph1 {
          font-size: 4rem;
        }

        .paragraph2 {
          font-size: 2rem;
        }

        img {
          height: 20px;
          width: auto;
          margin: $margin-xs 0;
        }
      }
    }
  }

  .gaming {
    & > .media {
      video {
        width: 100%;
      }
    }

    @include media($break-md) {
      & > .text {
        .paragraph1 {
          font-size: 4vw;
        }
      }
      & > .media {
        width: 100%;
      }
    }

    @include media($break-lg) {
      @include flex(row);

      & > .media {
        height: 100%;
        width: auto;

        video {
          display: block;
          height: 100%;
          width: auto;
        }
      }

      & > .text {
        height: 100%;
        background-color: white;
        color: black;

        & > .paragraph1 {
          font-size: 2rem;
          width: 450px;
        }
      }
    }
  }

  .stats {
    @include contentPage;
    
    color: white;
    text-align: center;
    
    .stats-inner {
      @include flex(column, space-evenly);

      height: 80%;
      width: 100%;
      margin-bottom: 15%;

      & > p {
        line-height: 1.2;
        color: white;
      }
  
      & > .barbs {
        @include flex(column);

        position: relative;
        width: 100%;
  
        .barb-wrapper {
          width: 100%;
  
          img {
            width: 100%;
          }
        }
  
        .run-wrapper {
          width: 100%;
  
          img {
            width: 100%;
            aspect-ratio: 327 / 171;
            object-fit: contain;
          }

          .spacer {
            opacity: 0;
            position: relative;
          }

          .gif {
            position: absolute;
            bottom: 0;
            left: 0;
            height: auto;
            width: auto;
          }
        }

        .rainbow-wrapper {
          position: absolute;
          bottom: 25%;
          right: -60%;
          width: 125%;

          img {
            width: 50%;
          }
        }

        .paragraph2 {
          font-size: 3vw;
          font-weight: 500;
          position: relative;
          width: 65%;
          background: conic-gradient(          
            #95b9ff,
            #d795ff,
            #ffa861,
            #ffd361,
            #95ffa0,
            #95b9ff,
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          line-height: 1;
        }
      }
    }

    @include media($break-md) {
      .stats-inner {
        margin-bottom: 10%;

        & > .barbs {
          .barb-wrapper, .run-wrapper {
            width: 75%;
          }

          .run-wrapper {
            @include flex;
          }
        }
      }
    }

    @include media($break-lg) {
      .stats-inner {
        flex-direction: row;
        justify-content: center;
        margin-bottom: 5%;
        width: 90%;

        & > .barbs {
          .barb-wrapper{
            width: 100%;
          }

          .paragraph1 {
            width: 20rem;
          }
          
          .paragraph2 {
            font-size: 1.5rem;
            width: 450px;
            margin-top: $margin-sm;
          }

          .rainbow-wrapper {
            bottom: 0;
            right: 50%;
            transform: translate(25%, 75%);
            width: 50vw;
          }
        }
      }
    }
  }

  .education {
    @include contentPage;

    padding: $margin-lg $margin-lg calc(15% + $margin-lg);
    position: relative;

    .shade {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.47) 33.29%,
        rgba(0, 0, 0, 0.46) 49.38%,
        rgba(0, 0, 0, 0.43) 65.52%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    .education-inner {
      @include flex(column, flex-start);

      width: 100%;
      height: 100%;
      position: relative;
    }

    .media, .text {
      width: 100%;
      height: 100%;
    }
    
    .media {
      position: absolute;

      & > .video-border {
        @include rainbow-border(2px, 4.5vmin);

        position: absolute;
        width: 47.5%;

        &.vb1 {
          top: 0;
          left: 0;
        }

        &.vb2 {
          right: 0;
          bottom: 0;
        }
      }
    }

    .text {
      position: absolute;
      top: 46.25%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - (2 * $margin-lg));
      height: min-content;
      text-align: center;
      color: white;

      .paragraph2 {
        font-size: 6.25vw;
        line-height: 1.2;
      }
    }

    @include media($break-lg) {
      padding: $margin-lg $margin-lg calc(5% + $margin-lg);

      .education-inner {
        height: 90%;
        width: 75%;
      }

      .media {
        & > .video-border {
          width: 22.5%;
  
          &.vb1 {
            top: 0;
            left: 0;
          }
  
          &.vb2 {
            right: 0;
            bottom: 0;
          }
        }
      }

      .text {
        width: 50%;

        .paragraph2 {
          font-size: 2rem;
        }
      }
    }
  }

  .entertainment-1 {
    flex-direction: column;
    background-color: white;
    overflow: hidden;

    & > .text {
      .desktop {
        display: none;
      }
      
      .mobile {
        display: block;
      }

      .paragraph1 {
        font-size: 7vw;
      }
    }

    & > .media {
      position: relative;
      width: 100%;
      height: 50%;
      overflow: visible;

      .m3 {
        display: none;
      }

      video {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 75%;
        filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
      }

      img {
        position: absolute;
        right: -1px;
        top: -5%;
        width: 55%;
        filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
      }
    }

    @include media($break-md) {
      .media {
        & > .m1 {
          width: 60%;
        }

        & > .m2 {
          width: 50%;
          top: -7.5%;
        }
      }

      .text {
        .paragraph1 {
          line-height: 1.2;
          font-size: 7vw;
        }
      }
    }

    @include media($break-lg) {
      flex-direction: row-reverse;

      .media {
        height: 100%;
        width: 60%;

        & > .m1 {
          bottom: initial;
          top: 5%;
          left: 15%;
          width: 66.67%;
        }

        & > .m2 {
          left: 4%;
          right: initial;
          top: 20%;
          width: 30%;
        }

        & > .video-border.m3 {
          @include rainbow-border;

          display: block;
          position: absolute;
          width: 25%;
          right: 0;
          top: 20%;
        }
      }

      .text {
        width: 60%;

        .desktop {
          display: block;
        }
        
        .mobile {
          display: none;
        }

        .paragraph1 {
          font-size: 2rem;
          margin: $margin-lg;
          width: 450px;
        }
      }
    }
  }

  .entertainment-2 {
    @include contentPage;
    @include flex(column);
    
    & > .media {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      
      & > video {
        width: 100%;
        height: 100%;
        object-fit: cover
      }

      & > .text {
        @include flex;
  
        position: absolute;
        height: 35%;
        width: 100%;
        left: 50%;
        bottom: 0;
        padding-bottom: 7.5%;
        transform: translateX(-50%);
        background: linear-gradient(
          0deg,
          #000000 -80.98%,
          rgba(0, 0, 0, 0) 100%
        );
  
        & > p {
          position: relative;
          text-align: center;
        }
      }
    }
  }

  .social {
    flex-direction: column;

    .text, .media {
      height: 50%;
      position: relative;
    }

    & > .text {
      padding: 7.5% $margin-lg 15%;
      position: relative;

      & > p {
        font-size: 5.75vw;
      }
    }

    .video-border {
      @include rainbow-border;

      display: block;
      height: 100%;
      position: relative;
      bottom: -5%;

      video {
        height: 100%;
        width: auto;
      }
    }

    @include media($break-md) {
      @include flex(column);

      .media {
        height: 50%;

        .video-border {
          height: calc(100% - (2 * $margin-lg));
        }
      }

      .text {
        height: 35%;
        flex: none;
      }
    }

    @include media($break-lg) {
      @include flex(row);

      .text, .media {
        height: 100%;
        width: 50%;
      }

      .text {
        padding: 0;

        .paragraph1 {
          font-size: 2rem;
          width: 450px;
        }
      }
    }
  }

  .experiences-1, .wine-and-spirits {
    .text, .media {
      height: 50%;
    }

    & > .text {
      & > p {
        font-size: 5.75vw;
      }
    }

    & > .media {
      width: 100%;

      video {
        display: block;
        width: 100%;
      }
    }

    @include media($break-lg) {
      @include flex(row-reverse);

      .text, .media {
        height: 100%;
        width: 50%;
      }

      .text {
        .paragraph1 {
          font-size: 2rem;
          width: 450px;
        }
      }
    }
  }

  .experiences-2 {
    position: relative;

    & > .backdrop {
      @include flex($wrap: wrap);

      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      img {
        width: 50%;
        height: calc(100% / 3);
        object-fit: cover;
        object-position: center center;
      }

      .shade {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }

    @include media($break-md) {
      .media {
        margin-bottom: 5%;
      }
    }

    @include media($break-lg) {
      justify-content: flex-start;

      .media {
        align-items: flex-start;
        margin: $margin-lg 0;
        height: 100%;

        .video-border {
          margin-top: $margin-lg;
          height: calc(100% - (6 * $margin-lg));
        }
      }

      & > .backdrop {
        img {
          width: calc(100% / 3);
          height: 50%;
        }
      }
    }
  }

  .wine-and-spirits {
    & > .media {

      & > video {
        width: auto;
        height: 100%;
        object-fit: cover;
      }
    }

    @include media($break-lg) {
      flex-direction: row;

      & > .media {
        & > video {
          height: 100%;
          width: auto;
        }
      }
    }
  }

  .machine-learning {
    @include contentPage;
    
    & > .text {
      position: absolute;
      bottom: 12.5%;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - (2 * $margin-lg));
      height: min-content;
      text-align: center;
      color: white;

      .paragraph2 {
        font-size: 4vmax;
        line-height: 1.2;  
      }

      @include media($break-xs) {
        .paragraph2 {
          font-size: 4vmax;
        }
      }

      .green {
        color: #C3FB80;
      }
    }

    & > video {
      position: absolute;
      width: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 5%;
    }

    @include media($break-md) {
      & > .text {
        bottom: 7.5%;

        .paragraph2 {
          font-size: 5.5vw;
        }
      }

      & > video {
        height: 72.5%;
      }
    }

    @include media($break-lg) {
      & > .text {
        @include flex;

        bottom: 12.5%;

        .paragraph2 {
          font-size: 2rem;
          width: 550px;
        }
      }

      & > video {
        height: 60%;
        margin-top: 3%;
      }
    }
  }

  .looking-forward {
    @include contentPage;

    .text {
      @include flex(column, space-evenly);
      height: 85%;
    }

    .doubleline {
      @include flex(column);

      flex: 1;
      font-family: $typeface-tertiary;
      font-weight: 800;
      font-size: 16vw;
      text-align: center;
      line-height: 0.75;
      width: 100%;
      color: transparent;
      background-clip: text;
      -webkit-text-stroke-color: white;
      -webkit-text-stroke-width: 1px;
      
      .looking {
        flex: 1;
        font-size: 20.1vw;
      }
      
      .forward {
        flex: 1;
        font-size: 16.8vw;
      }
    }

    @include media($break-lg) {
      .doubleline {
        .looking {
          font-size: 15vmin;
        }
        
        .forward {
          font-size: 12.6vmin;
        }
      }
    }
  }
}
