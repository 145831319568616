@use '../../styles/constants.scss' as *;

.subheading {
  font-size: 17vw;
  font-weight: 800;
  line-height: 1;
  margin: 0;
  text-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
  
  @include media($break-lg) {
    font-size: 4rem;
  }
}
