@use '../../styles/constants.scss' as *;

.landing {
  @include page;
  
  color: white;

  .contents {
    @include flex(column, space-evenly);

    height: 100%;
    width: 100%;
    padding-bottom: 15%;
  }

  .text {
    position: relative;
    margin: 0 $margin-lg;
  }

  @include media($break-md) {
    .text {
      width: 80%;
    }
  }

  @include media($break-lg) {
    .text {
      position: absolute;
      bottom: 20%;
      width: 480px;
    }
  }
}
