@use '../../styles/constants.scss' as *;

.project-detail {
  @include contentPage;
  
  justify-content: flex-start;
  color: white;
  overflow: hidden;

  .top-text {
    height: 100%;
    margin-top: 22.5%;
    overflow-y: scroll;
    width: 100%;
    padding: 0 $margin-xl;

    .paragraph1 {
      font-size: 1.5rem;
      line-height: 1.2;
      margin: 5% 0;
    }

    .drop-us {
      font-weight: 500;
      margin-bottom: 2.5%;
    }
  }

  @include media($break-xs) {
    .top-text {
      .paragraph1 {
        font-size: 1.2rem;
      }

      .paragraph2 {
        font-size: 1rem;
      }

      .drop-us {
        font-size: 0.8rem;
      }
    }
  }

  @include media($break-lg) {
    .top-text {
      @include flex(column, flex-start);

      width: calc(100% + $margin-lg);
      overflow-y: scroll;
      margin-top: 5%;

      & > * {
        width: 40%;
      }

      .paragraph1 {
        margin: $margin-xl 0;
      }
  
      .paragraph2 {
        font-size: 1.2rem;
      }

      & > p.paragraph2 {
        margin-top: 2.5%;
      }

      .contact-form {
        .cta {
          margin-top: $margin-lg;
        }
      }
    }
  }
}
